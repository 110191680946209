import "./about.css"
import { SKILLS } from "./skills.js";
import { motion, useMotionTemplate, useMotionValue  } from 'framer-motion';
import { useState, MouseEvent } from "react";



function About({ isChecked }) {
  

  const revealTech = {
    rest: { opacity: 0, ease: "easeOut", duration: 0.2, type: "tween" },
    hover: {
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  };

    return (
        <div id="about" className="h-contain w-screen aboutbackground relative">
             <div className="max-w-screen-xl p-8 mx-auto flex flex-col align-center justify-center">
             <motion.h2
                               initial={{ opacity: 0}}
                               whileInView={{ opacity: 100}}
                               viewport={{ once: true }}
                               transition={{ delay: 0.2 ,duration: 1 }}className="text-2xl font-medium tracking-widest">{isChecked ? "TECH" : "TECH"}</motion.h2>
                    <div className=" md:w-1/2 mt-5 md:p-2">
            {SKILLS.map((skill, index) => (
              <motion.span
              initial={{ opacity: 0}}
              whileInView={{ opacity: 100}}
              viewport={{ once: true }}
              transition={{ delay: 0.2 ,duration: 1 }}
              key={index} className="inline-block px-3 py-1 text-sm font-light bg-gray-100 text-gray-800 mr-2 mb-2 rounded-sm tracking-widest">
                {skill.name}
              </motion.span>
            ))}
          </div>
                <div className=" flex flex-col justify-center mt-10">
                    <motion.h2
                                     initial={{ opacity: 0}}
                                     whileInView={{ opacity: 100}}
                                     viewport={{ once: true }}
                                     transition={{ duration: 1 }}
                    className="text-2xl font-medium tracking-widest">{isChecked ? "OM MIG" : "ABOUT ME"}</motion.h2>
                     <motion.p
                                                          initial={{ opacity: 0}}
                                                          whileInView={{ opacity: 100}}
                                                          viewport={{ once: true }}
                                                          transition={{ duration: 1 }}
                     className="md:w-2/3 mt-5 font-light leading-loose md:text-xl tracking-wide">{isChecked ? "Jeg studerer på nuværende tidspunkt en professionsbachelor i webudvikling på Zealand i Roskilde. Jeg er passioneret omkring interessante og brugervenlige web applikationer, og jeg nyder at udforske nye teknologier og teknikker til at forbedre mine evner." : "I am currently studying for a bachelor’s degree in web development at Zealand in Roskilde. I am passionate about interesting and user-friendly web applications, and I enjoy exploring new technologies and techniques to enhance my skills."}</motion.p>
                </div>
                <div className=" flex flex-col md:justify-center mt-10">
          <motion.div
                       initial={{ opacity: 0}}
                       whileInView={{ opacity: 100}}
                       viewport={{ once: true }}
                       transition={{ delay: 0.2 ,duration: 1 }}
           id="contact" className="h-96 flex mt-10 flex-col ">
                    <h3 className="tracking-widest font-medium text-2xl">{isChecked ? "KONTAKT INFORMATION" : "CONTACT"}</h3>
                    <p className="tracking-widest font-light text-lg mt-4">4014 1319</p>
                    <p className="tracking-widest font-light text-lg mt-4">jacobgervin@gmail.com</p>

                </motion.div>
                </div>
            </div>
            <div className="absolute bottom-5 w-full text-center text-xs"> Created by <a href="https://www.linkedin.com/in/jacob-gervin-25457b212/" target="_blank" rel="noopener noreferrer" className="navlink">Jacob Gervin</a> </div>
        </div>
    );
  }
  
  export default About;
  