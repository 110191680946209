export const SKILLS = [
    {
        "name": "HTML",

      },
      {
        "name": "CSS",

      },
      {
        "name": "JAVASCRIPT",

      },
      {
        "name": "TYPESCRIPT",

      },
      {
        "name": "C#",

      },
      {
        "name": ".NET",

      },
      {
        "name": "PHP",

      },
      {
        "name": "REACT",

      },
      {
        "name": "ANGULAR",

      },
      {
        "name": "VUE",

      },
      {
        "name": "LARAVEL",

      },
      {
        "name": "TAILWIND",

      },
      {
        "name": "BOOTSTRAP",

      },
      {
        "name": "NODEJS",

      },
      {
        "name": "SQL",

      },
      {
        "name": "WORDPRESS",

      },
      {
        "name": "UMBRACO",

      },


]