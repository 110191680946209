import "./home.css"
import Work from "./work";
import About from "./about";
import Scroll from "../components/Scroll";
import { motion } from "framer-motion";

function Home({ isChecked }) {
  return (
    <>
<div id="pagehero" className="hero h-screen md:h-screen w-screen flex flex-col justify-center relative">
    <div className="max-w-screen-xl p-3 md:p-8 mx-auto my-auto  flex flex-col relative">
        <motion.div
                              initial={{ opacity: 0}}
                              whileInView={{opacity: 100}}
                              viewport={{ once: true }}
                              transition={{ delay: 0.2 ,duration: 1 }}
        className="z-20 flex  flex-col md:text-center md:items-center">
        <h1 
        className="font-bold text-2xl md:text-4xl jg tracking-wide">JACOB GERVIN</h1>
        <h2 className="font-medium text-xl md:mt-6 md:text-1xl webdev tracking-wide"> {isChecked ? "FULLSTACK WEB UDVIKLER" : "FULLSTACK WEB DEVELOPER"}</h2>
        <div className="flex flex-row items-center gap-2 md:mt-6 mt-2">
           <motion.div className="indicator"                                             
                animate={{ opacity: 0.6 }}
                transition={{
                    duration: 1,
                    repeat: Infinity,
                    repeatType: "reverse",
                }}>
                                </motion.div> <h2 className="font-light text-xs md:text-sm webdev tracking-wide"> {isChecked ? "LEDER EFTER ARBEJDE" : "OPEN TO WORK"}</h2></div>
        </motion.div>
    </div>
    <div className="max-w-screen-xl p-3 md:p-8 bottom-8 left-6 md:left-20 absolute ">
        <Scroll />
    </div>
</div>
</>
  );
}

export default Home;