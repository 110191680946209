import * as React from "react";
import "./loading.css";
import { motion } from "framer-motion";



function Loading() {


    return (
<motion.div
                              initial={{ backgroundColor: "#000"}}
                              whileInView={{backgroundColor: "#f8f8f8"}}
                              viewport={{ once: true }}
                              transition={{delay: 3, duration: 2 }}
className="LoadingContainer">
<div className="Welcome">Welcome!</div>
</motion.div>

    );
  }
  
  export default Loading;
  