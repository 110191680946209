import * as React from "react";
import "./scrolloutput.css"


function Scroll() {


    return (
<div className="scrollContainer">
<div className="mouse"></div>
<p className="scrollText">Scroll</p>
</div>



    );
  }
  
  export default Scroll;
  