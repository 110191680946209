import "./work.css"
import { PROJECTS } from "./projects";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import GithubIcon from "../components/icons/githubIcon";




function Work({ isChecked, openModal }) {

    const projects = PROJECTS;
    
    const handleProjectClick = (projectId) => {
        openModal(projectId);
    };


    return (
<div id="work" className="h-fit w-screen workbackground">
  <div className="max-w-screen-xl p-8 mx-auto flex flex-col">
    <motion.div
                                  initial={{ opacity: 0}}
                                  whileInView={{opacity: 100}}
                                  viewport={{ once: true }}
                                  transition={{ delay: 0.2 ,duration: 1 }}
    className="relative">
        <div className="z-20 relative">
            <h1 className=" font-light tracking-widest text-2xl md:mt-20 worktitle">{isChecked ? "PROJEKTER" : "PROJECTS"}</h1>
        </div>

    </motion.div>
    <div
    className="mx-auto columns-1 md:columns-2 lg:columns-3 gap-8 mt-10 ">
{projects.map((project, index) => (
        <div className="mb-8">
        <motion.div 
                 initial={{ opacity: 0}}
                 whileInView={{ opacity: 100}}
                 viewport={{ once: true }}
                 transition={{ delay: 0.2 ,duration: 1 }}
        className="bg-neutral-700 relative overflow-hidden rounded-lg hover:font-bold  group project"
        onClick={() => handleProjectClick(project.id)}
        >
            <img src={project.image} alt={project.name} className="object-cover hover:scale-110 transition duration-300 w-full aspect-square"/>
            <div className="absolute w-full top-4 flex flex-row justify-evenly">
            {project.techstack.map((tech) => (
                    <span key={tech} className="inline-block px-1 py-1 text-[10px] font-light text-gray-800 mr-1 ml-1 mb-2 bg-purple ">{tech}</span>
                  ))}
            </div>
        </motion.div>
        <div className="ml-2 mt-3 mr-2 flex flex-row items-center justify-between">
        <button onClick={() => handleProjectClick(project.id)} className="navlink  text-[13px]">{project.name}</button>
        <a href={project.github} target="_blank" rel="noopener noreferrer" className="right-4 bottom-4 flex flex-row items-center cursor-pointer">
              <GithubIcon />
            </a>
        </div>
        </div>
))}

        
    </div>

  </div>
</div>
    );
  }
  
  export default Work;
  