import * as React from "react";
import { useState } from 'react';
import { Link, animateScroll } from 'react-scroll';
import {motion, useCycle} from "framer-motion";
import hamburger from './hamburger.json'
import Lottie from 'lottie-react'
import "./navbar.css"
import LanguagePicker from "./languagepicker";
import GithubIcon from "./icons/githubIcon";
import LinkedinIcon from "./icons/linkedinIcon";

function Navbar({ isChecked, setIsChecked }) {

  const [isOpen, toggleOpen] = useCycle(false, true);
  const [showNav, setShowNav] = useState(true)
  const variants = {
    open: { opacity: 1},
    closed: { opacity: 0},
    toggleOn: {},
    toggleOff: {}
  }

  const workvariant = {
    open: {x: 0,opacity:1},
    closed: {x: -100, opacity:0},
    toggleOn: {},
    toggleOff: {}
  }

    return (


<motion.nav 
                              initial={{ opacity: 0}}
                              whileInView={{opacity: 100}}
                              viewport={{ once: true }}
                              transition={{ delay: 0.2 ,duration: 1 }}
class="fixed w-full z-30 top-0 left-0 navbar">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-8">
    <div className="md:w-40">
  <Link 
            to="pagehero"
            smooth={true}
            onClick={() => {
              const section = document.getElementById('pagehero');
              if (section) {
                animateScroll.scrollTo(section.offsetTop);
              } else {
                window.location.href = '/';
              }
            }}
  class="flex items-center navlink w-fit">
      <span class="self-center text-md font-normal whitespace-nowrap tracking-widest navbarbrand">JACOB GERVIN</span>
  </Link>
  </div>

  <div class="flex md:order-2 justify-end w-40 gap-3">
  {/* <LanguagePicker  isChecked={isChecked} setIsChecked={setIsChecked}  /> */}
    <a href="https://github.com/jacobgervin" className="flex flex-row items-center cursor-pointer hover:drop-shadow-xl hidden md:block" target="_blank" rel="noopener noreferrer">
<GithubIcon />
    </a>
    <a href="https://www.linkedin.com/in/jacob-gervin-25457b212/" className="flex flex-row items-center hover:drop-shadow-xl cursor-pointer hidden md:block" target="_blank" rel="noopener noreferrer">
      <LinkedinIcon />
    </a>

      <button onClick={() => setShowNav(!showNav)} type="button" class="inline-flex items-center p-2 text-sm text-gray-500 md:hidden  focus:outline-none">
        <span class="sr-only">Open main menu</span>
        <svg class="w-6 h-6" fill="#000" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
      </button>
  </div>
  <motion.div 
   class={`flex flex-col justify-center block md:hidden w-screen h-[88vh] ${showNav && 'hidden'}`}>
    <motion.ul
          animate={!showNav ? "open" : "closed"}
          variants={variants}
          transition={{ duration: 0.5 }}
    className='h-96 flex flex-col justify-evenly mb-10'>
      <li>        
         <motion.Link 

animate={!showNav ? "open" : "closed"}
variants={workvariant}
transition={{ duration: 0.25 }}
          to="work"
          smooth={true}
          onClick={() => {
            
            const section = document.getElementById('work');
            
            if (section) {
              animateScroll.scrollTo(section.offsetTop);
            } else {
              window.location.href = '/';
            }
            setShowNav(!showNav)
          }}
        class="block py-2 pr-4 text-4xl rounded md:bg-transparent md:p-0 navlink cursor-pointer">{isChecked ? "PROJEKTER" : "PROJECTS"}</motion.Link></li>
      <li>
                <motion.Link 
                animate={!showNav ? "open" : "closed"}
                variants={workvariant}
                transition={{ delay:0.25, duration: 0.25 }}
          to="about"
          smooth={true}
          onClick={() => {
            const section = document.getElementById('about');
            if (section) {
              animateScroll.scrollTo(section.offsetTop);
            } else {
              window.location.href = '/';
            }
            setShowNav(!showNav)
          }}
        class="block py-2 pr-4 text-4xl rounded md:bg-transparent md:p-0 navlink cursor-pointer">{isChecked ? "OM MIG" : "ABOUT"}</motion.Link>
      </li>
      <li>
      <motion.Link 
                      animate={!showNav ? "open" : "closed"}
                      variants={workvariant}
                      transition={{ delay:0.5, duration: 0.25 }}
          to="contact"
          smooth={true}
          onClick={() => {
            const section = document.getElementById('contact');
            if (section) {
              animateScroll.scrollTo(section.offsetTop);
            } else {
              window.location.href = '/';
            }
            setShowNav(!showNav)
          }}
        class="block py-2 pr-4 text-4xl rounded md:bg-transparent md:p-0 navlink cursor-pointer">{isChecked ? "KONTAKT" : "CONTACT"}</motion.Link>
      </li>
      <li>
      <motion.div
                animate={!showNav ? "open" : "closed"}
                variants={variants}
                transition={{ duration: 0.5 }}
      class="flex md:order-2 space-x-3 justify-start w-full py-2 pr-4">
    <a href="https://github.com/jacobgervin" className="flex flex-row items-center" target="_blank" rel="noopener noreferrer">
          <GithubIcon />
    </a>
    <a href="https://www.linkedin.com/in/jacob-gervin-25457b212/" target="_blank" rel="noopener noreferrer" className="flex flex-row items-center" >
          <LinkedinIcon />
    </a>
  </motion.div>
      </li>
    </motion.ul>

  </motion.div>
  <div class={`items-center justify-between hidden w-full md:flex md:w-auto md:order-1 `}>
    <ul class="flex flex-col p-4 md:p-0 mt-4  tracking-widest md:flex-row md:space-x-[100px] md:mt-0 navbarlinks">
      <li>
        <Link 
          to="work"
          smooth={true}
          onClick={() => {
            const section = document.getElementById('work');
            if (section) {
              animateScroll.scrollTo(section.offsetTop);
            } else {
              window.location.href = '/';
            }
          }}
        class="block py-2 pl-3 pr-4 text-xs rounded md:bg-transparent md:p-0 navlink cursor-pointer">{isChecked ? "PROJEKTER" : "PROJECTS"}</Link>
      </li>
      <li>
        <Link 
          to="about"
          smooth={true}
          onClick={() => {
            const section = document.getElementById('about');
            if (section) {
              animateScroll.scrollTo(section.offsetTop);
            } else {
              window.location.href = '/';
            }
          }}
        class="block py-2 pl-3 pr-4 text-xs rounded md:bg-transparent md:p-0 navlink cursor-pointer">{isChecked ? "OM MIG" : "ABOUT"}</Link>
      </li>
      <li>
        <Link 
          to="contact"
          smooth={true}
          onClick={() => {
            const section = document.getElementById('contact');
            if (section) {
              animateScroll.scrollTo(section.offsetTop);
            } else {
              window.location.href = '/';
            }
          }}
        class="block py-2 pl-3 pr-4 text-xs rounded md:bg-transparent md:p-0 navlink cursor-pointer">{isChecked ? "KONTAKT" : "CONTACT"}</Link>
      </li>
    </ul>
  </div>
  </div>
</motion.nav>


    );
  }
  
  export default Navbar;
  