
import Navbar from "./components/navbar";
import Home from "./pages/home";
import Workpage from "./pages/workpages/workpage";
import Work from "./pages/work";
import About from "./pages/about";
import {Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Loading from "./components/loading";
import AnimatedCursor from "react-animated-cursor";
import Modal from "./components/Modal";


function App() {

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWorkId, setSelectedWorkId] = useState(null);

  const openModal = (id) => {
    setSelectedWorkId(id);
    setIsModalOpen(true);
    document.body.classList.add('noScroll');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove('noScroll');
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000); 
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if device is mobile
    const checkMobile = () => {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    
    setIsMobile(checkMobile());

    // Timeout for loading state
    setTimeout(() => {
      setIsLoading(false);
    }, 5000); 
  }, []);

  return (
    <>
     {!isMobile && (
<AnimatedCursor
  innerSize={8}
  outerSize={35}
  innerScale={1}
  outerScale={2}
  outerAlpha={0}
  hasBlendMode={true}
  innerStyle={{
    backgroundColor: '#000'
  }}
  outerStyle={{
    border: '2px dotted #000'
  }}
  clickables={[
    '.project',
    'a',
    'input[type="text"]',
    'input[type="email"]',
    'input[type="number"]',
    'input[type="submit"]',
    'input[type="image"]',
    'label[for]',
    'select',
    'textarea',
    'button',
    '.link',
    '.navlink'

  ]}
/>
     )}
          {isLoading && <Loading />}
          {!isLoading && 
           <>
           <Navbar isChecked={isChecked} setIsChecked={setIsChecked} />
           <Home isChecked={isChecked} />
           <Work isChecked={isChecked} openModal={openModal} />
           <About isChecked={isChecked} />
           {/* ... other components, if any */}
           {isModalOpen && <Modal selectedWorkId={selectedWorkId} closeModal={closeModal} />}
         </>
          }
    </>
  );
}

export default App;
