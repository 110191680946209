import * as React from "react";
import { PROJECTS } from "../pages/projects"; // Update the path as needed
import "./modal.css";
import { motion } from "framer-motion";
import GithubIcon from "./icons/githubIcon";
import WebIcon from "./icons/webIcon";

function Modal({ selectedWorkId, closeModal }) {
    const selectedProject = PROJECTS.find(project => project.id === selectedWorkId);

    if (!selectedProject) {
        // Handle case where no project is found
        return null;
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="modalContainer"
        >
            <motion.div 
                        initial={{ opacity: 0.5, y: "100vh" }}
                        animate={{opacity: 1, y: "0" }}
                        exit={{opacity: 0.5, y: "100vh" }}
                        transition={{duration: 0.6 }}
            className="modalContent mt-10 flex flex-col md:items-center justify-center relative">
                <div className="md:w-3/5 flex flex-col">
                <div className="absolute right-8 top-4 gap-3 flex"> <a href={selectedProject.github} target="_blank" rel="noopener noreferrer"> <GithubIcon /> </a> <a href={selectedProject.live} target="_blank" rel="noopener noreferrer" > <WebIcon /> </a> </div>
                <h2 className="md:text-sm text-xs mt-10">Project Name:</h2>
                <h1 className="md:text-lg text-sm font-bold mt-2">{selectedProject.name}</h1>
                <img className="md:mt-10 mt-5 rounded-lg" src={selectedProject.image} alt={selectedProject.name} />
                <div className="flex mt-3 flex-wrap">
                {selectedProject.techstack.map((tech) => (
                    <span key={tech} className="inline-block px-3 py-1 text-sm font-light text-[12px] bg-gray-100 rounded-md text-gray-800 mr-2 mb-2 tracking-widest">{tech}</span>
                  ))}
                  </div>
                <h2 className="md:text-sm font-medium text-sm md:mt-8 mt-3">Project background</h2>
                <p className="md:text-sm text-xs mt-2" >{selectedProject.about}</p>
                {selectedProject.requirements && selectedProject.requirements.length > 0 && (
                    <div>
                        <h2 className="md:text-sm font-medium text-sm md:mt-8 mt-3">Project requirements</h2>
                        <ul className="flex flex-col md:w-1/4 mt-2">
                            {selectedProject.requirements.map((req) => (
                                <li key={req} className="inline-block px-3 py-1 text-sm font-light text-[12px] bg-gray-100 rounded-md text-gray-800 mr-2 mb-2 tracking-widest">{req}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <h2 className="md:text-sm font-medium text-sm md:mt-8 mt-3">Project duration</h2>
                <p className="md:text-sm text-xs mt-2" >{selectedProject.duration}</p>
                <h2 className="md:text-sm font-medium text-sm md:mt-8 mt-3">Group size</h2>
                <p className="md:text-sm text-xs mt-2" >{selectedProject.groupsize}</p>
                <button className="absolute left-0 top-0 w-10 h-10 bg-black text-white rounded-sm" onClick={closeModal}>X</button>
                </div>
            </motion.div>
        </motion.div>
    );
}

export default Modal;
